<template>
  <div class="ip_contacts">
    <h1>{{ $t('system.ip_contact') }}</h1>
    <p>{{ $t('views.tools.ip_contact.description') }}</p>

    <Loading v-if="message_pending"></Loading>

    <b-alert
      :show="message_success === true"
      dismissible
      variant="success">
      {{ $t('views.tools.ip_contact.success') }}
    </b-alert>
    <b-alert
      :show="message_success === false"
      dismissible
      variant="danger">
      {{ $t('views.tools.ip_contact.failed') }}{{ failure_reason }}
    </b-alert>
    <b-alert
      :show="check_cidr === false && show_ip_error"
      variant="danger">
      {{ $t('views.tools.ip_contact.invalid_ip') }}
    </b-alert>
    <b-form @submit="submitMessage">
      <b-form-group :label="$t('system.ip_address_or_cidr') + ':'">
        <b-input required v-model="cidr" :placeholder="$t('system.ip_address_or_cidr')" :state="check_cidr" @change="() => {if (cidr.length === 0) {show_ip_error = false}}"></b-input>
      </b-form-group>
      <b-textarea v-model="message" :placeholder="$t('views.tools.ip_contact.message')" required :state="message.length > 0 ? undefined : false">

      </b-textarea>
      <b-button type="submit" class="mt-3" variant="outline-success" :disabled="!can_submit" :style="'cursor: ' + (can_submit ? 'auto' : 'not-allowed')">
        <netvs-icon icon="execute"></netvs-icon>
        {{ $t('views.tools.ip_contact.submit') }}
      </b-button>
    </b-form>
  </div>
</template>
<script>

import Ip_contactService from '@/api-services/ip_contact.service'
import ipaddress from '@/util/ipaddress'
import Loading from '@/components/Loading.vue'

export default {
  name: 'IPContact',
  components: { Loading },
  data() {
    return {
      cidr: '',
      message: '',
      message_pending: false,
      message_success: undefined,
      failure_reason: '',
      show_ip_error: false,
    }
  },
  methods: {
    async submitMessage(ev) {
      ev.preventDefault()

      if (!this.check_cidr) {
        this.show_ip_error = true
        return false
      }
      this.show_ip_error = false

      this.message_success = undefined
      this.failure_reason = ''

      this.message_pending = true
      Ip_contactService.submit_message(this.$store.state, this.cidr, this.message).then(r => {
        this.message_success = true
        this.cidr = ''
        this.message = ''
        setTimeout(() => { this.message_success = undefined }, 10_000)
      }).catch(e => {
        if (!e.response) {
          this.failure_reason = this.$t('system.internal_error')
        } else if (e.response.status === 404) {
          this.failure_reason = e.response.data.detail[this.$store.state.locale] || e.response.detail.en
          this.message_success = false
        } else {
          throw e
        }
      }).finally(() => {
        this.message_pending = false
      })
      return true
    }
  },
  computed: {
    check_cidr() {
      if (this.cidr.length === 0) {
        return undefined
      }
      return ipaddress.is_ip_v6(this.cidr.split('/')[0]) || ipaddress.is_ip_v4(this.cidr)
    },
    can_submit() {
      return this.check_cidr && this.message.length > 0
    }
  }
}
</script>
